.testimonial {
    display: flex;
    // gap: -40px;
    // margin-block-start: 64px;

    @include media-breakpoint-down(lg) {
        // margin-block-start: 48px;
    }

    @include media-breakpoint-down(md) {
        margin-block-start: 0;
        flex-direction: column;
    }

    &__figure {
        flex-shrink: 0;
        align-self: baseline;
        display: flex;
        aspect-ratio: 1/1;
        border-radius: 100vh;
        width: 100%;
        height: auto;
        max-width: 300px;
        margin: 0;
        padding: 0;
        overflow: clip;
        transform-origin: left center;


        
        @include media-breakpoint-down(md) {
            max-width: 200px;
            transform-origin: left top;
            scale: 1.1;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    &__content {
        align-self: center;
        // background-color: var(--bg-light);
        padding: 0px 40px 40px 40px;

        @include media-breakpoint-down(md) {
            padding: 48px 32px 32px 32px;
        }

        &--name {
            display: block;
            font-size: 18px;
        }

        &--zitat {
            font-size: 22px;
            display: block;
            font-weight: 400;
            color: var(--color-tertiary);
            margin-bottom: 1rem;
            font-style: italic;
        }
        &--stimme {
            color: var(--color-tertiary);
            font-weight: bold;
        }
    }
}