.siegel {
    position: fixed;
    display: flex;
    align-items: center;
    transition: all 0.5s ease-in-out;
    padding: 10px;
    background-color: white;
    border-radius: 0 0 10px 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
    z-index: 999;

    @media all and (max-height: 550px) {
        display: none !important;
    }

    @include media-breakpoint-down(sm) {
        min-height: 80px;
        box-shadow: none;
        padding: 7px;
        border-radius: 10px;
    }

    &.hideSiegel {
        transform: translateY(-20px);
        opacity: 0;
        visibility: hidden;
        @include media-breakpoint-down(sm) {
            transform: translateX(-20px);
        }
    }

    a {
        display: flex;
    }

    img {
        max-width: 125px;
        max-height: 150px;
        height: auto;

        @include media-breakpoint-down(lg) {
            max-height: 100px;
        }

        @include media-breakpoint-down(sm) {
            max-width: 100px;
        }
    }


    &.capital {
        inset: 0px auto auto 613px;
        @include media-breakpoint-down(lg) {
            inset: 0px auto auto 409px;
        }
        @include media-breakpoint-down(sm) {
            inset: 116px auto auto 179px;     
        }
    }
    &.handelsblatt {
        inset: 0px auto auto 350px;
        @include media-breakpoint-down(lg) {
            inset: 0px auto auto 225px;
        }
        @include media-breakpoint-down(sm) {
            inset: 116px auto auto 5px;     
        }
    }
    &.meinstiftungsexperte {
        inset: 0px auto auto 460px;
        @include media-breakpoint-down(lg) {
            inset: 0px auto auto 303px;
                }
        @include media-breakpoint-down(sm) {
            inset: 116px auto auto 78px;     
        }
    }
}