.figure {
    margin: 0;
    padding: 0;

    &--md {
        max-width: 640px;
    }

    &--sm {
        max-width: 480px;
    }

    &--xs {
        max-width: 320px;
    }

    .img {
        width: 100%;
        height: auto;
        display: block;
        &.rounded {
            border-radius: 550px;
        }
    }
    
    .figcaption {
        margin-block-start: .5rem;
        font-size: 13px;
        font-weight: 400;
        font-style: italic;
        color: var(--meta);
    }


}

[data-img-size="1/1"] {
    aspect-ratio: 1/1;
    object-fit: cover;
    object-position: center;
    width: 100%;
    // height: 100%;
}

[data-img-size="16/9"] {
    aspect-ratio: 16/9;
    object-fit: cover;
    object-position: center;
    width: 100%;
    // height: 100%;
}

[data-img-position="top"] {
    object-position: center 0 !important;
}

[data-img-position="top-mid"] {
    object-position: center 25% !important;
}

[data-img-position="mid-bottom"] {
    object-position: center 75% !important;
}

[data-img-position="bottom"] {
    object-position: center 100% !important;
}


.pswp-gallery {
    display: grid;
	gap: 20px;
	grid-template-columns: repeat(2, minmax(0, 1fr));

    a {
        text-decoration: none;
        display: inline-block;
        // aspect-ratio: 1/1;
        overflow: hidden;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
    &.grid-1 {
        grid-template-columns: repeat(1, minmax(0, 1fr));

    }
    &.grid-3 {
        grid-template-columns: repeat(3, minmax(0, 1fr));
        @include media-breakpoint-down(md) {
            grid-template-columns: repeat(2, minmax(0, 1fr));

        }

        a {
           aspect-ratio: 4/3;
        }
    }
    &.grid-4 {
        grid-template-columns: repeat(4, minmax(0, 1fr));
        @include media-breakpoint-down(md) {
            grid-template-columns: repeat(2, minmax(0, 1fr));

        }

        a {
           aspect-ratio: 1/1;
        }
    }
}
