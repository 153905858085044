.video {
    width: 100%;
    aspect-ratio: 16/9;

    iframe {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
}

.thumbnail-grid {
  display: grid;
grid-template-columns: repeat(2, 1fr);
gap: 20px;
@include media-breakpoint-down(sm) {
  grid-template-columns: repeat(1, 1fr);
}
  .grid-item {
  }
}
