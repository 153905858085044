.logo {
    position: fixed;
    inset: 0 auto auto 40px;
    display: flex;
    align-items: center;
    @include effect(all, .5s, ease-in-out);
    // transition-delay: .25s;
    padding: 20px;
    background-color: white;
    border-radius: 0 0 10px 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
    z-index: 999;

    @include media-breakpoint-down(lg) {
        inset: 0 auto auto 20px;
    }

    @include media-breakpoint-down(sm) {
        inset: 0 0 auto 0;
        border-radius: 0;
        min-height: 80px;
    }

    &.hide {
        transform: translateY(-20px);
        opacity: 0;
        visibility: hidden;
    }

    a {
        display: flex;
    }

    img {
        width: 260px;
        height: auto;
        @include media-breakpoint-down(lg) {
            width: 160px;
        }

        @include media-breakpoint-down(sm) {
            width: 220px;
        }
    }
}